<template>
  <div>
    <div style="overflow-x:scroll">
      <table class="table mt-3">
        <thead class="thead-light">
            <tr>
              <th>Title</th>
              <th>Score</th>
              <th>Out of</th>
              <th>Grade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4"><strong>Homework</strong></td>
            </tr>
            <tr v-if="!gradesObj.Homework || gradesObj.Homework.length == 0">
              <td colspan="4" class="pl-5 text-muted"><em>No assignments</em></td>
            </tr>
            <tr v-else v-for="item, idx in gradesObj.Homework" :key="`hw${idx}`">
              <td class="pl-5">{{ item.title }}</td>
              <td v-if="item.points&&item.releasegrades">{{ item.points }}</td>
              <td v-else>-</td>
              <td>{{ item.totalpoints }}</td>
              <td v-if="item.points&&item.releasegrades">
              {{ ((item.points/item.totalpoints)*100).toFixed(2) }}%</td>
              <td v-else class="text-muted"><em>Not graded</em></td>
            </tr>
            <tr>
              <td colspan="4"><strong>Quizzes</strong></td>
            </tr>
            <tr v-if="!gradesObj.Quiz || gradesObj.Quiz.length == 0">
              <td colspan="4" class="pl-5 text-muted"><em>No assignments</em></td>
            </tr>
            <tr v-else v-for="item, idx in gradesObj.Quiz" :key="`quiz${idx}`">
              <td class="pl-5">{{ item.title }}</td>
              <td v-if="item.points&&item.releasegrades">{{ item.points }}</td>
              <td v-else>-</td>
              <td>{{ item.totalpoints }}</td>
              <td v-if="item.points&&item.releasegrades">
              {{ ((item.points/item.totalpoints)*100).toFixed(2) }}%</td>
              <td v-else class="text-muted"><em>Not graded</em></td>
            </tr>
            <tr>
              <td colspan="4"><strong>Exams</strong></td>
            </tr>
            <tr v-if="!gradesObj.Exam || gradesObj.Exam.length == 0">
              <td colspan="4" class="pl-5 text-muted"><em>No assignments</em></td>
            </tr>
            <tr v-else v-for="item, idx in gradesObj.Exam" :key="`exam${idx}`">
              <td class="pl-5">{{ item.title }}</td>
              <td v-if="item.points&&item.releasegrades">{{ item.points }}</td>
              <td v-else>-</td>
              <td>{{ item.totalpoints }}</td>
              <td v-if="item.points&&item.releasegrades">
              {{ ((item.points/item.totalpoints)*100).toFixed(2) }}%</td>
              <td v-else class="text-muted"><em>Not graded</em></td>
            </tr>
            <tr style="border-top: 3px double black">
              <td></td>
              <td></td>
              <td><strong>Total</strong></td>
              <td v-if="Number.isNaN(totalGrade)" class="text-muted"><em>Not available</em></td>
              <td v-else><strong>{{ totalGrade.toFixed(2) }}%</strong></td>
            </tr>
          </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'StudentGrades',
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    totalGrade() {
      const calc = ((this.gradesObj.pointsSum / this.gradesObj.weightageGraded) * 100);
      return calc;
    },
  },
  data() {
    return {
      gradesObj: [],
    };
  },
  methods: {
    getGrades() {
      axios.get(`/api/grades/${this.courseid}/student`, {
        params: {
          userid: this.userData.id,
        },
      })
        .then((response) => {
          this.gradesObj = response.data;
          console.log(this.gradesObj);
        });
    },
  },
  mounted() {
  },
  beforeMount() {
    this.courseid = this.$route.params.cid;
    this.getGrades();
  },
};
</script>
