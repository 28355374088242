<template>
  <div style="padding-top: 2rem" class="info-container p-0 mx-auto">
    <div v-if="userData.roleId == 2">
      <TeacherGrades></TeacherGrades>
    </div>
    <div v-else-if="userData.roleId == 1">
      <StudentGrades></StudentGrades>
    </div>
  </div>
</template>

<script>
import StudentGrades from './StudentGrades.vue';
import TeacherGrades from './TeacherGrades.vue';

export default {
  name: 'CourseDashboard',
  components: {
    StudentGrades,
    TeacherGrades,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {

    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>
</style>
