<template>
  <div>
    <div style="overflow-x:scroll">
      <table v-if="gradesObj" class="table table-bordered table-hover mt-3">
        <thead>
          <tr>
            <th rowspan="2">Student</th>
            <th v-if="Object.keys(gradesObj.assessments.Homework).length > 0"
              :colspan="Object.keys(gradesObj.assessments.Homework).length">
              Homework
            </th>
            <th v-if="Object.keys(gradesObj.assessments.Quiz).length > 0"
              :colspan="Object.keys(gradesObj.assessments.Quiz).length">
              Quizzes
            </th>
            <th v-if="Object.keys(gradesObj.assessments.Exam).length > 0"
              :colspan="Object.keys(gradesObj.assessments.Exam).length">
              Exams
            </th>
            <th rowspan="2"><strong>Total</strong></th>
          </tr>
          <tr class="table-secondary">
            <th v-for="ass, idx in gradesObj.assessments.Homework" :key="idx">
              {{ ass.title }}
            </th>
            <th v-for="ass, idx in gradesObj.assessments.Quiz" :key="idx">{{ ass.title }}</th>
            <th v-for="ass, idx in gradesObj.assessments.Exam" :key="idx">{{ ass.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student, idx in gradesObj.students" :key="idx">
            <td>{{ student.displayname }}</td>
            <td v-for="grade, assid in student.Homework" :key="assid">
              <span v-if="grade">{{ ((grade/gradesObj.assessments.Homework[assid].totalpoints)
              * 100).toFixed(1) }}%</span>
              <span v-else>-</span>
            </td>
            <td v-for="grade, assid in student.Quiz" :key="assid">
              <span v-if="grade">{{ ((grade/gradesObj.assessments.Quiz[assid].totalpoints)
              * 100).toFixed(1) }}%</span>
              <span v-else>-</span>
            </td>
            <td v-for="grade, assid in student.Exam" :key="assid">
              <span v-if="grade">{{ ((grade/gradesObj.assessments.Exam[assid].totalpoints)
              * 100).toFixed(1) }}%</span>
              <span v-else>-</span>
            </td>
            <td>
              <span v-if="student.average !== null">{{ student.average.toFixed(1) }}%</span>
              <span v-else><em>NA</em></span>
            </td>
          </tr>
          <tr>
            <td><strong>Average</strong></td>
            <td v-for="ass, idx in gradesObj.assessments.Homework" :key="idx">
              <span v-if="ass.statistics && ass.statistics.mean !== null"><strong>
              {{ ((ass.statistics.mean/ass.totalpoints) * 100).toFixed(1) }}%</strong></span>
              <span v-else><strong>-</strong></span>
            </td>
            <td v-for="ass, idx in gradesObj.assessments.Quiz" :key="idx">
              <span v-if="ass.statistics && ass.statistics.mean !== null"><strong>
              {{ ((ass.statistics.mean/ass.totalpoints) * 100).toFixed(1) }}%</strong></span>
              <span v-else><strong>-</strong></span>
            </td>
            <td v-for="ass, idx in gradesObj.assessments.Exam" :key="idx">
              <span v-if="ass.statistics && ass.statistics.mean !== null"><strong>
              {{ ((ass.statistics.mean/ass.totalpoints) * 100).toFixed(1) }}%</strong></span>
              <span v-else><strong>-</strong></span>
            </td>
            <td>
              <span v-if="gradesObj.average !== null"><strong>
              {{ gradesObj.average.toFixed(1) }}%</strong></span>
              <span v-else><strong><em>NA</em></strong></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TeacherGrades',
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    totalGrade() {
      const calc = ((this.gradesObj.pointsSum / this.gradesObj.weightageGraded) * 100);
      return calc;
    },
  },
  data() {
    return {
      gradesObj: null,
    };
  },
  methods: {
    getGrades() {
      axios.get(`/api/grades/${this.courseid}/teacher`)
        .then((response) => {
          this.gradesObj = response.data;
        });
    },
  },
  mounted() {
  },
  beforeMount() {
    this.courseid = this.$route.params.cid;
    this.getGrades();
  },
};
</script>
